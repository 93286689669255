@import "src/client_customizations/scss/client_master.scss";

.ButtonGroup {
	.btn {
		background-color: $ButtonGroup-btn-background-color; // $background_color_1;
		&:hover {
			background-color: $ButtonGroup-btn-active-background-color;
			color: $ButtonGroup-btn-hover-color
		}
	}
	.btn.active {
		background-color: $ButtonGroup-btn-active-background-color;
	}
}
