@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
	// .social-media-row {
	// 	text-align: center;
	// }
	.social-media-icon {
		height: 48px;
	}
	.footer-border {
		border-right: 1px solid #73767B;
	}
	// .zr-row {
	// 	justify-content: center;
	// }

	.dte-row {
		justify-content: center;
	}

	.pad-right {
		padding-right: 8px;
	}

	.pad-left {
		padding-left:8px;
	}
	.p {
		color: $GenericFooter-links-color;
	}
	.row {
		margin-left: 0px;
	}
	.form-control {
		width: 100%;
	}
}

@media screen and (max-width: 992px) {
	.Footer {
		.footer-border {
			border-right: 0px solid #73767B;
		}
		.zr-row{
			flex-direction: column;
			align-items: center;
		}
		.pad-right {
			padding-right: 0px;
		}
		.pad-left {
			padding-left:0px;
		}
	}
}

.disclaimer-container {
       div {
              padding:0 !important;
              background-color:transparent !important;

              img {
                     display: block;
                     max-width: 100%;
                     margin:0 auto 20px;
              }

              p {
                     color:white;
                     font-size:11px;
                     line-height: 2em;
              }
       }
}