@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		// background-color: $HomePage-big-promise-background-color;
		// color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#homepage-cards-container {
	background-color: #EFEFE9;
	padding: 0rem;
}
#HomepageVehiclesCarousel {
	background-color: #fff;
}
#HomepageIncentives {
	background-color: #fff;
}
.hp-welcome-sub {
	font-size: 1rem;
	margin-top: 1rem;
	padding: 0rem 25rem;
}
.hp-welcome {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px)  {
	.homepage-jumbotron {
		.big-promise {
		padding-top: 0px;
		padding-left: 10px;
		padding-right: 10px;
		padding-bottom: 120px;
		}
	}
	.hp-welcome-sub {
		margin-top: 0px;
		padding: 0rem;
	}
	.hp-welcome {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
	.hp-welcome-sub {
		font-size: 1rem;
		margin-top: 1rem;
		padding: 0rem 10rem;
	}
}
