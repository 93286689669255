@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
	background-color: #F5F5F0;
		.compared-vehicle-container {
			padding-top: 20px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}
}